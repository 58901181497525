<!-- template -->
<template>
  <div class="sogym">

    <div class="sogym__left">

      <div class="sogym__input">
        <p>Қорытынды сумма</p>
        <input type="int" v-model="amount">
      </div>


      <div class="sogym__payment--type item__row item__ac">
        <button :class="type === 'kaspi_gold' ? 'btn__active' : ''" class="btn btn-dark" @click="type='kaspi_gold'">
          KASPI GOLD
        </button>

        <button :class="type === 'kaspi_qr' ? 'btn__active' : ''" class="btn btn-dark" @click="type='kaspi_qr'">KASPI
          QR
        </button>

        <button :class="type === 'jusan' ? 'btn__active' : ''" class="btn btn-dark" @click="type='jusan'">JUSAN</button>
      </div>

      <button class="sogym__save" @click="saveResult">Сақтау</button>

    </div>


    <div class="sogym__right">

    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      amount: 1,
      type: ''
    }
  },
  mounted() {

  },
  watch: {
    activeLang(val) {
      localStorage.setItem('lang', val);
      this.getContentWord(val);
    }
  },
  methods: {
    saveResult() {
      this.$axios({
        method: 'post',
        url: `${this.$API_URL}delete/phone`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data', // Add this line for proper handling of form data
        },
        data: '',
      })
      .then(response => {
        this.$toast.open({
          message: response.data.message,

          position: "bottom",
          duration: 4000,
          queue: true,
          className: 'custom-toast',
        });
      })
      .catch(error => {
        console.log(error);
      });
    },
    getLoginRule() {
      return this.activeLang == 1 ? this.loginRules : this.loginEngRules;
    },
    getPasswordRule() {
      return this.activeLang == 1 ? this.passwordRules : this.passwordEngRules;
    },
    getContentWord(val) {
      this.loading = true;
      let link = 'lang'
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            link,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          'Accept-Language': val == 1 ? 'ru' : 'en'
        },
      })
          .then((response) => {
            this.content = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    registration() {
      let obj = {
        email: this.login,
        password: this.password,
      }
      this.$axios({
        method: 'post',
        url: this.$API_URL + 'registration',
        data: obj,
        headers: {
          'Accept-Language': localStorage.getItem('lang') == 1 ? 'ru' : 'en'
        },
      })
          .then((response) => {
            localStorage.setItem('access_token', response.data.token)
            response.data.role != 1 ? this.$router.push('/profile') : this.$router.push('/orders');
            localStorage.setItem('role', response.data.role);
          })
          .catch((error) => {
            let errors = error.response.data.errors;
            for (let variable in errors) {
              this.$toast.open({
                message: errors[variable][0],
                type: "warning",
                position: "bottom",
                duration: 4000,
                queue: true,
              });
              continue;
            }
          });
    }
  }
}
</script>

<style scoped lang="scss">
.sign__page__top {
  align-self: center;
  text-align: center;
  width: 80%;
  font-size: 10px;
  font-weight: bold;
}

.sign__page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media only screen and (max-width: 764px) {
    width: 100%;
  }

  .sign__page__title {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;
    color: #333;
    @media only screen and (max-width: 900px) {
      font-size: 18px;
    }
  }

  .sign__page__block {
    display: flex;
    flex-direction: column;
    width: 400px;
    @media only screen and (max-width: 900px) {
      width: 90%;
    }

    input {
      width: 100px;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      @media only screen and (max-width: 764px) {
        width: 100%;

      }
    }

    ::placeholder {
      color: #333;
    }

    button {
      cursor: pointer;
      text-transform: uppercase;
      width: 100%;
      outline: none;
      border: none;
      background-color: cornflowerblue;
      padding: 20px;
      @media only screen and (max-width: 764px) {
        width: 100%;
      }

      p {
        color: white;
        font-weight: bold;
        margin-bottom: 0;

      }
    }

    button:hover {
      background-color: #285bb6;
    }
  }

}

.active__lang {
  color: blue;
}


.sogym {

  .sogym__left {
    padding: 30px;
    margin-right: 20px;
    border-right: 3px solid black;
    width: 50%;

    .btn__active {
      background-color: coral !important;
    }

    .sogym__input {
      input {
        border: 2px solid black;
        padding: 20px;

      }
    }

    .sogym__payment--type {
      margin-top: 20px;
      margin-bottom: 10px;

      button {
        padding: 20px;
        background: black;
        color: white;
        margin-right: 20px;
        font-weight: bold;
      }

      button:hover {
        background-color: coral;
      }
    }
  }

  .sogym__right {

  }

  .sogym__save {
    background-color: green;
    color: white;
    padding: 20px;
  }
}

</style>
